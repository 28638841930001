import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
import { Accordion, AccordionItem } from '@sb1/ffe-accordion-react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Accordion headingLevel={2} mdxType="Accordion">
    <AccordionItem heading="Hva bør være en del av designsystemet, og hva bør ikke?" mdxType="AccordionItem">
        Designsystemet består av løsninger og retningslinjer som er relevante for de fleste produktteam. Det finnes ikke et tydelig fasitsvar på hvilke retningslinjer eller komponenter som hører hjemme her, men som en tommelfingerregel skal alle deler av systemet komme til nytte for to eller flere team. Komponenter bør være generiske nok til at alle team kan bruke de, og tydelig utformet i SpareBank 1-drakt. Retningslinjene gjelder i stor grad alle, og beskriver hvordan vi bruker SpareBank 1-merkevaren i digitale flater. Ta gjerne kontakt med designsystem-teamet dersom du lurer på om noe konkret passer inn eller ikke!
    </AccordionItem>
    <AccordionItem heading="Når kommer endringer ut?" mdxType="AccordionItem">
      Endringer i npm-pakkene blir publisert hver dag klokkeb 09:00, 12:00, 14:00 og 16:00.
      Dokumentasjonen blir oppdatert hver gang det skjer endringer i den, men må trigges manuelt for å plukke opp endringer i npm-pakkene.
    </AccordionItem>
    <AccordionItem heading="Hvordan ser jeg hva som er nytt?" mdxType="AccordionItem">Designsystem-teamet deler jevnlig rundt hva som skjer i designsystemet – blant annet i designforum, nyhetsbrev og på Slack. Ved endringer i designsystem-bibliotekene i Figma, så vil du få en melding i Figma om at det finnes en oppdatering, med valg om å oppdatere skissene med en gang eller vente til senere. Endringer i npm-pakkene blir publisert som en ny release, og alle pakker har en CHANGELOG-fil man kan se nærmere på for å få med seg alle endringer.</AccordionItem>
    <AccordionItem heading="Hvordan kontakter jeg designsystem-teamet?" mdxType="AccordionItem">Vi kan kontaktes via slack i #ext-designsystem kanalen, eller via mail til <a href="mailto:designsystem@sparebank1.no">designsystem@sparebank1.no</a></AccordionItem>
    </Accordion>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      